import { Container } from '../components/Container'

const faqs = [
  [
    {
      question: 'How accurate are your results?',
      answer:
        'Accuracy, is incredibly difficult to say. In our tests we are settling at around 90% correct for true positive correct identification.',
    },
    {
      question: 'How are you different from other services?',
      answer:
        'We\'re comrades facing the same problem. However, digitalfalse is working towards identifying any AI created text, not just essay submissions.',
    },
    {
      question: 'Are digitalfalse\'s analysis guaranteed?',
      answer: 'We are providing the information and analysis you need to help you make that decision.',
    },
    {
      question: 'How does digitalfalse work?',
      answer:
        'We have huge, possible world leading dataset of AI and Human generated responses. On this we train AI, and statistical models to identify whether a text has more traits in line with AI or Human.',
    },
  ],
  [
    {
      question: 'What if I\'m not happy with the results?',
      answer:
        'We\'re constantly improving the modelling, and our dataset. We hope to work together to identify why your texts aren\'t getting high sensitivity results.',
    },
    {
      question: 'What makes you different from other AI detection tools?',
      answer:
        'Digitalfalse is an AI detection system, built on machine learning, Founder Joshua Croft has 3 academic years specialising in NLP, and has been employed as a NLP developer at two startups developing smart chat bots. digitalfalse isn\'t just about validating essays.',
    },
      ]
]

export function Faqs() {
  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-slate-50 py-20 sm:py-32"
    >
      {/*<Image*/}
      {/*  className="absolute top-0 left-1/2 max-w-none translate-x-[-30%] -translate-y-1/4"*/}
      {/*  src={backgroundImage}*/}
      {/*  alt=""*/}
      {/*  width={1558}*/}
      {/*  height={946}*/}
      {/*  unoptimized*/}
      {/*/>*/}
      <Container className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faq-title"
            className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
          >
            Frequently asked questions
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            If you can’t find what you’re looking for, email our support team.
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"
        >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-lg leading-7 text-slate-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-slate-700">{faq.answer}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
