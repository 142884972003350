import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { HashLink } from 'react-router-hash-link';
import { Button } from '../components/Button'
import { Container } from '../components/Container'
import { Logo } from '../components/Logo'
import { NavLink } from '../components/NavLink'
import {Link} from "react-router-dom";
import {SignupButton} from "./buttons/signup-button";
import {LogoutButton} from "./buttons/logout-button";
import {LoginButton} from "./buttons/login-button";
import {useAuth0} from "@auth0/auth0-react";

function MobileNavLink({ href, children }) {
  return (
    // <Popover.Button as={Link} href={href} className="block w-full p-2">
    //   {children}
    // </Popover.Button>
      <></>
  )
}



function MobileNavIcon({ open }) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={clsx(
          'origin-center transition',
          open && 'scale-90 opacity-0'
        )}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={clsx(
          'origin-center transition',
          !open && 'scale-90 opacity-0'
        )}
      />
    </svg>
  )
}

function MobileNavigation() {
  return (
    <Popover>
      <Popover.Button
        className="relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <MobileNavIcon open={open} />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >

                            <Popover.Panel
                  as="div"
                  className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
              >
                <HashLink to="#features">Features</HashLink>
                <HashLink to="#pricing">Pricing</HashLink>
                <hr className="m-2 border-slate-300/40"/>
                              <SignupButton/>
                              <LoginButton/>

              </Popover.Panel>

        </Transition.Child>
      </Transition.Root>
    </Popover>
  )
}

export function Header() {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  return (
    <header className="py-10">
      <Container>
        <nav className="relative z-50 flex justify-between">
          <div className="flex items-center md:gap-x-12">
            <Link to="/" aria-label="Home">
              <Logo/>
            </Link>
            <div className="hidden md:flex md:gap-x-6">
              <HashLink to="/#features">Features</HashLink>
              <HashLink to="/#pricing">Pricing</HashLink>
            </div>
          </div>
          <div className="flex items-center gap-x-5 md:gap-x-8">

            {isAuthenticated &&
                <>
                <Link to="/dashboard">Dashboard</Link>
              <LogoutButton/>
                </>
            }

            {!isAuthenticated &&
                <>
                <div className="hidden md:block">

                  <LoginButton/>

                </div>
              <SignupButton/>
                </>
            }
            <div className="-mr-1 md:hidden">
              <MobileNavigation authenticated={isAuthenticated}/>
            </div>
          </div>
        </nav>
      </Container>
    </header>
  )
}
