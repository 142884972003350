import {useAuth0} from "@auth0/auth0-react";
import {Footer} from '../components/Footer'
import {Header} from '../components/Header'
import React, {useEffect, useState} from "react";
import {apiClassification, apiGetDashboard} from "../api";
import {Container} from "../components/Container";

export const Notes: React.FC = () => {

    return (
        <>
            <header>
                <title>Digitalfalse - Detecting AI in text and image</title>
            </header>
            <Header/>

            <div className="max-w-7xl justify-center" style={{minHeight: 'calc(100vh - 137px)', margin: "0 auto"}}>
                <div className="max-w-7xl justify-center p-2" style={{padding: "5%"}}>
                    <div>
                        <h2>10/03/2023 </h2>
                        <h2>v0.01</h2>
                        <h2 className="pt-2 px-5%">
                            Our launch! This is very exciting for us, as we’re really passionate about this problem. So,
                            let’s get into what we’re releasing.

                            digitalfalse.ai v0.01 is an early, stable version of our text classification software. It’s
                            a statistical model based on lots of data. It’s going live, and free at an early stage so
                            that we can develop this in the open, and improve this together.

                            Our primary goal with v0.001 is to gain user insights about what is, and isn’t working.

                            One thing we're observing in our models is that we're very accurate in detecting in a QA
                            format, as in, go and ask ChatGPT a question and then paste it here and we're more likely
                            than not able to detect this.

                            Right now, digitalfalse is struggling when comparing stories, or fiction. That’s our next
                            target, we’ll be tweaking the model that digitalfalse.ai uses for the initial phase to be
                            our general model, which isn’t as accurate in single domains but best overall across all.

                            There are some rate limits in place, just to keep services up. We’ll scale these when
                            necessary.

                            Have fun, and do send us feedback!


                        </h2>
                    </div>
                </div>

            </div>

            <Footer/>
        </>
    )
}
