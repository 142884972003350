
export function Logo() {
  return (

<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="200px" height="48px" viewBox="0 0 250 48"  xmlSpace="preserve">


<path d="M17.4,39.9c-1.5,0-2.8-0.5-3.8-1.6s-1.6-2.3-1.7-3.8c-1.6-0.2-3-1-4.1-2.3c-1.1-1.3-1.6-2.8-1.6-4.6c0-0.6,0.1-1.2,0.2-1.8
	c0.2-0.6,0.4-1.2,0.7-1.7c-0.4-0.6-0.8-1.2-1-1.9c-0.2-0.7-0.3-1.4-0.3-2.2c0-1.8,0.6-3.3,1.8-4.6c1.2-1.3,2.7-2,4.4-2.2
	c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-1.5,0.5-2.8,1.6-3.8c1-1,2.3-1.6,3.9-1.6c1,0,1.9,0.2,2.5,0.6c0.7,0.4,1.4,1,2,1.8
	c0.7-0.8,1.4-1.4,2-1.8C24.7,7.2,25.5,7,26.6,7c1.5,0,2.7,0.5,3.8,1.6c1.1,1.1,1.6,2.3,1.6,3.8v0.4c0,0.1,0,0.2,0,0.3
	c1.8,0.1,3.2,0.9,4.5,2.2c1.2,1.3,1.8,2.8,1.8,4.6c0,0.8-0.1,1.6-0.4,2.3c-0.3,0.7-0.6,1.3-1,1.9c0.3,0.6,0.6,1.2,0.7,1.8
	c0.2,0.6,0.3,1.2,0.3,1.9c0,1.8-0.5,3.3-1.6,4.6c-1.1,1.3-2.5,2-4.1,2.2c-0.1,1.4-0.6,2.7-1.7,3.8c-1,1.1-2.3,1.6-3.8,1.6
	c-1.1,0-2-0.2-2.6-0.6c-0.6-0.4-1.3-1-2-1.8c-0.7,0.8-1.4,1.4-2.1,1.8C19.3,39.7,18.5,39.9,17.4,39.9z M22.8,12.3v22.3
	c0,1,0.4,1.9,1.1,2.6c0.8,0.7,1.7,1.1,2.8,1.1c1,0,1.9-0.4,2.7-1.2c0.7-0.8,1.1-1.6,1.2-2.6c-1-0.1-2-0.4-2.8-0.9
	c-0.9-0.5-1.6-1.1-2.2-2c-0.2-0.2-0.2-0.3-0.2-0.5c0-0.2,0.2-0.4,0.3-0.5c0.2-0.1,0.4-0.2,0.6-0.1c0.2,0,0.4,0.2,0.5,0.3
	c0.5,0.7,1.1,1.2,1.8,1.6c0.8,0.4,1.6,0.5,2.4,0.5c1.5,0,2.8-0.5,3.8-1.5c1-1,1.5-2.3,1.5-3.8c0-0.4,0-0.8-0.1-1.2
	c-0.1-0.4-0.2-0.8-0.4-1.2c-0.6,0.5-1.2,0.9-2,1.1c-0.7,0.3-1.5,0.4-2.3,0.4c-0.2,0-0.4-0.1-0.6-0.2c-0.1-0.1-0.2-0.3-0.2-0.6
	s0.1-0.4,0.2-0.6c0.1-0.1,0.3-0.2,0.6-0.2c1.5,0,2.7-0.5,3.7-1.6c1-1,1.5-2.3,1.5-3.8c0-1.4-0.5-2.7-1.5-3.7c-1-1-2.2-1.6-3.6-1.6
	c-0.3,0.7-0.7,1.4-1.3,1.9c-0.6,0.5-1.2,0.9-1.9,1.2c-0.2,0.1-0.4,0.1-0.6,0c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4,0-0.6
	c0.1-0.2,0.2-0.3,0.4-0.4c0.8-0.3,1.4-0.8,1.8-1.4c0.4-0.7,0.7-1.5,0.7-2.3c0-1.1-0.4-2-1.1-2.8s-1.6-1.2-2.7-1.2s-1.9,0.4-2.7,1.1
	C23.1,10.4,22.8,11.3,22.8,12.3z M21.2,34.6V12.3c0-1-0.4-1.9-1.1-2.6c-0.7-0.7-1.6-1.1-2.7-1.1c-1,0-1.9,0.4-2.7,1.2
	c-0.8,0.8-1.1,1.7-1.1,2.8c0,0.9,0.2,1.6,0.7,2.3c0.5,0.7,1.1,1.1,1.8,1.4c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.2,0.1,0.4,0.1,0.6
	c-0.1,0.2-0.2,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.6,0c-0.7-0.3-1.4-0.7-1.9-1.2c-0.5-0.5-1-1.2-1.3-1.9c-1.4,0.1-2.6,0.7-3.5,1.7
	c-1,1-1.5,2.2-1.5,3.7c0,1.5,0.5,2.8,1.5,3.8c1,1,2.2,1.6,3.7,1.6c0.2,0,0.4,0.1,0.6,0.2c0.1,0.1,0.2,0.3,0.2,0.6
	c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.1-0.3,0.2-0.6,0.2c-0.8,0-1.6-0.1-2.3-0.4c-0.7-0.3-1.4-0.6-2-1.1c-0.2,0.4-0.3,0.8-0.4,1.2
	c-0.1,0.4-0.1,0.8-0.1,1.2c0,1.5,0.5,2.8,1.5,3.9c1,1,2.3,1.5,3.7,1.5c0.9,0,1.7-0.2,2.4-0.6c0.7-0.4,1.4-0.9,1.9-1.6
	c0.1-0.2,0.3-0.3,0.5-0.3c0.2-0.1,0.4,0,0.6,0.1c0.2,0.2,0.3,0.3,0.3,0.5c0,0.2,0,0.4-0.1,0.5c-0.6,0.8-1.3,1.5-2.2,2
	c-0.9,0.5-1.8,0.8-2.8,0.8c0,1,0.4,1.9,1.2,2.7c0.8,0.8,1.7,1.2,2.7,1.2c1.1,0,2-0.4,2.7-1.1C20.9,36.5,21.2,35.7,21.2,34.6z"/>
<rect x="164.4" y="23.2" className="st0" width="0.5" height="2.1"/>
<g className="st1">
</g>
<path d="M42.5,25c0-3.4,0.8-6.1,2.3-8.2c1.5-2,3.6-3,6.2-3c3,0,5.2,1.2,6.8,3.5V5h2.4v31.2h-2.3l-0.1-2.9c-1.6,2.2-3.8,3.3-6.9,3.3
	c-2.5,0-4.6-1-6.1-3.1c-1.6-2-2.3-4.8-2.3-8.3V25z M44.9,25.4c0,2.8,0.6,5,1.7,6.6c1.1,1.6,2.7,2.4,4.8,2.4c3,0,5.1-1.3,6.4-4V20.2
	c-1.3-2.9-3.4-4.3-6.3-4.3c-2,0-3.6,0.8-4.8,2.4C45.5,19.9,44.9,22.3,44.9,25.4z M66.4,7.9c0-0.5,0.1-0.8,0.4-1.2
	c0.3-0.3,0.7-0.5,1.2-0.5s0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2c0,0.5-0.2,0.8-0.5,1.2c-0.3,0.3-0.7,0.5-1.2,0.5S67.2,9.3,66.9,9
	C66.6,8.7,66.4,8.3,66.4,7.9z M69.3,36.2h-2.4v-22h2.4V36.2z M74.9,25c0-3.4,0.8-6.2,2.3-8.2c1.5-2,3.6-3,6.2-3c3,0,5.3,1.2,6.8,3.6
	l0.1-3.2h2.3v21.5c0,2.8-0.8,5.1-2.4,6.7c-1.6,1.7-3.7,2.5-6.4,2.5c-1.5,0-3-0.3-4.4-1c-1.4-0.7-2.5-1.6-3.3-2.7l1.4-1.5
	c1.7,2.1,3.8,3.2,6.1,3.2c2,0,3.6-0.6,4.8-1.8c1.1-1.2,1.7-2.9,1.8-5v-2.8c-1.6,2.2-3.8,3.3-6.8,3.3c-2.5,0-4.6-1-6.1-3
	c-1.5-2-2.3-4.8-2.3-8.2V25z M77.4,25.4c0,2.8,0.6,5,1.7,6.6c1.1,1.6,2.7,2.4,4.8,2.4c3,0,5.1-1.3,6.4-4V20.3
	c-0.6-1.4-1.4-2.5-2.5-3.2c-1.1-0.7-2.4-1.1-3.9-1.1c-2,0-3.6,0.8-4.8,2.4C77.9,19.9,77.4,22.3,77.4,25.4z M98.8,7.9
	c0-0.5,0.1-0.8,0.4-1.2c0.3-0.3,0.7-0.5,1.2-0.5s0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.7,0.5,1.2c0,0.5-0.2,0.8-0.5,1.2
	c-0.3,0.3-0.7,0.5-1.2,0.5S99.6,9.3,99.3,9C99,8.7,98.8,8.3,98.8,7.9z M101.7,36.2h-2.4v-22h2.4V36.2z M112.1,8.6v5.6h4.6v2h-4.6
	v14.7c0,1.2,0.2,2.1,0.7,2.7c0.4,0.6,1.2,0.9,2.2,0.9c0.4,0,1.1-0.1,2-0.2l0.1,2c-0.6,0.2-1.5,0.3-2.6,0.3c-1.7,0-2.9-0.5-3.6-1.5
	c-0.8-1-1.1-2.4-1.1-4.3V16.2h-4v-2h4V8.6H112.1z M135.6,36.2c-0.2-0.7-0.4-1.7-0.5-3.1c-0.9,1.1-1.9,2-3.3,2.6
	c-1.3,0.6-2.7,0.9-4.2,0.9c-2.1,0-3.8-0.6-5.1-1.8s-2-2.7-2-4.5c0-2.1,0.9-3.8,2.7-5.1c1.8-1.2,4.3-1.9,7.4-1.9h4.4v-2.5
	c0-1.6-0.5-2.8-1.5-3.7c-1-0.9-2.4-1.4-4.2-1.4c-1.7,0-3.1,0.4-4.2,1.3c-1.1,0.9-1.7,1.9-1.7,3.1l-2.4,0c0-1.7,0.8-3.3,2.4-4.5
	c1.6-1.3,3.6-1.9,6-1.9c2.5,0,4.4,0.6,5.8,1.8c1.4,1.2,2.1,2.9,2.2,5.1v10.4c0,2.1,0.2,3.7,0.7,4.8v0.2H135.6z M127.9,34.5
	c1.6,0,3.1-0.4,4.4-1.2c1.3-0.8,2.2-1.8,2.8-3.2v-4.8h-4.3c-2.4,0-4.3,0.5-5.7,1.3c-1.4,0.9-2.1,2-2.1,3.5c0,1.2,0.5,2.3,1.4,3.1
	C125.3,34.1,126.5,34.5,127.9,34.5z M146.7,36.2h-2.4V5h2.4V36.2z M155.1,36.2v-20h-3.6v-2h3.6v-2.7c0-2.2,0.6-4,1.7-5.2
	c1.1-1.2,2.7-1.8,4.8-1.8c0.9,0,1.8,0.1,2.6,0.3l-0.2,2c-0.7-0.2-1.4-0.2-2.2-0.2c-1.3,0-2.4,0.4-3.1,1.3s-1.1,2-1.1,3.6v2.7h5.2v2
	h-5.2v20H155.1z M181,36.2c-0.2-0.7-0.4-1.7-0.5-3.1c-0.9,1.1-1.9,2-3.3,2.6c-1.3,0.6-2.7,0.9-4.2,0.9c-2.1,0-3.8-0.6-5.1-1.8
	c-1.3-1.2-2-2.7-2-4.5c0-2.1,0.9-3.8,2.7-5.1c1.8-1.2,4.3-1.9,7.4-1.9h4.4v-2.5c0-1.6-0.5-2.8-1.5-3.7c-1-0.9-2.4-1.4-4.2-1.4
	c-1.7,0-3.1,0.4-4.2,1.3c-1.1,0.9-1.7,1.9-1.7,3.1l-2.4,0c0-1.7,0.8-3.3,2.4-4.5c1.6-1.3,3.6-1.9,6-1.9c2.5,0,4.4,0.6,5.8,1.8
	c1.4,1.2,2.1,2.9,2.2,5.1v10.4c0,2.1,0.2,3.7,0.7,4.8v0.2H181z M173.3,34.5c1.6,0,3.1-0.4,4.4-1.2c1.3-0.8,2.2-1.8,2.8-3.2v-4.8
	h-4.3c-2.4,0-4.3,0.5-5.7,1.3c-1.4,0.9-2.1,2-2.1,3.5c0,1.2,0.5,2.3,1.4,3.1C170.7,34.1,171.9,34.5,173.3,34.5z M192.1,36.2h-2.4V5
	h2.4V36.2z M211.9,30.6c0-1.2-0.5-2.2-1.5-2.9c-1-0.7-2.4-1.3-4.4-1.7s-3.5-0.9-4.5-1.4c-1.1-0.5-1.9-1.2-2.4-2
	c-0.5-0.8-0.8-1.7-0.8-2.8c0-1.7,0.7-3.2,2.2-4.3s3.3-1.7,5.6-1.7c2.5,0,4.4,0.6,5.9,1.8s2.2,2.8,2.2,4.7h-2.4
	c0-1.3-0.5-2.4-1.6-3.2c-1.1-0.9-2.4-1.3-4.1-1.3c-1.6,0-2.9,0.4-3.9,1.1s-1.5,1.6-1.5,2.8c0,1.1,0.4,1.9,1.2,2.6
	c0.8,0.6,2.3,1.2,4.4,1.7c2.1,0.5,3.7,1,4.8,1.6c1.1,0.6,1.9,1.3,2.4,2.1c0.5,0.8,0.8,1.8,0.8,2.9c0,1.9-0.7,3.3-2.2,4.5
	s-3.5,1.7-5.9,1.7c-2.6,0-4.6-0.6-6.2-1.9c-1.6-1.2-2.4-2.8-2.4-4.7h2.4c0.1,1.4,0.7,2.5,1.8,3.3s2.6,1.2,4.4,1.2
	c1.7,0,3.1-0.4,4.1-1.1C211.4,32.7,211.9,31.8,211.9,30.6z M228.3,36.6c-1.9,0-3.6-0.5-5.1-1.4c-1.5-0.9-2.7-2.2-3.5-3.9
	c-0.8-1.6-1.3-3.5-1.3-5.5V25c0-2.1,0.4-4,1.2-5.7c0.8-1.7,2-3,3.4-4c1.5-1,3-1.5,4.8-1.5c2.7,0,4.8,0.9,6.4,2.7
	c1.6,1.8,2.3,4.3,2.3,7.5v1.4h-15.7v0.5c0,2.5,0.7,4.6,2.1,6.2c1.4,1.7,3.2,2.5,5.4,2.5c1.3,0,2.4-0.2,3.4-0.7s1.9-1.2,2.7-2.3
	l1.5,1.2C234.3,35.3,231.7,36.6,228.3,36.6z M227.9,15.9c-1.8,0-3.4,0.7-4.6,2c-1.3,1.3-2,3.1-2.3,5.4h13.2V23
	c-0.1-2.1-0.7-3.8-1.8-5.2C231.2,16.5,229.7,15.9,227.9,15.9z"/>
</svg>


  )
}
