import { useId } from 'react'
import { Tab } from '@headlessui/react'
import clsx from 'clsx'

import { Container } from '../components/Container'
import screenshotContacts from '../images/screenshots/contacts.png'
import screenshotInventory from '../images/screenshots/inventory.png'
import screenshotProfitLoss from '../images/screenshots/profit-loss.png'

const features = [
  {
    name: 'Learning',
    summary: 'The more use digitalfalse has, the better our algorithms will become.',
    description:
      'Learning from user uploads is one of our sources of information, every upload is a new challenge that we\'re happy to face. ',
    image: screenshotProfitLoss,
    icon: function ReportingIcon() {
      let id = useId()
      return (
        <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path fill="#fff" d="M479 936 189 777V537L40 456l439-240 441 240v317h-60V491l-91 46v240L479 936Zm0-308 315-172-315-169-313 169 313 172Zm0 240 230-127V573L479 696 249 571v170l230 127Zm1-240Zm-1 74Zm0 0Z"/></svg>
      )
    },
  },
  {
    name: 'Indicators',
    summary:
      'Detailed breakdowns of text classification.',
    description:
      'Simple English explanations of classification, through to complex decision trees, helping you understand where text raises doubt.',
    image: screenshotInventory,
    icon: function InventoryIcon() {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path fill="#fff"  d="M480 936v-71l216-216 71 71-216 216h-71ZM120 726v-60h300v60H120Zm690-49-71-71 29-29q8-8 21-8t21 8l29 29q8 8 8 21t-8 21l-29 29ZM120 561v-60h470v60H120Zm0-165v-60h470v60H120Z"/></svg>
      )
    },
  },
  {
    name: 'Free',
    summary:
      'The system is currently in a learning phase.',
    description:
      'As we continue to feed the system with classified texts you can expect to see better results. We\'re uploading thousands of classified texts each day, creating a greater understanding of Human and AI authored texts.',
    image: screenshotContacts,
    icon: function PriceIcon() {
      return (
<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path fill="#fff"  d="M559 959q-18 18-43.5 18T472 959L97 584q-10-10-13.5-21T80 540V236q0-26 17-43t43-17h304q12 0 24 3.5t22 13.5l373 373q19 19 19 44.5T863 655L559 959Zm-41-41 304-304-378-378H140v304l378 378ZM245 392q21 0 36.5-15.5T297 340q0-21-15.5-36.5T245 288q-21 0-36.5 15.5T193 340q0 21 15.5 36.5T245 392ZM140 236Z"/></svg>
      )
    },
  },

]

function Feature({ feature, isActive, className, ...props }) {
  return (
    <div
      className={clsx(className)}
      {...props}
    >
      <div
        className={clsx(
          'w-11 rounded-lg',
        )}
      >

        <feature.icon/>

      </div>
      <h3
        className={clsx(
          'mt-6 text-sm font-medium text-white'
        )}
      >
        {feature.name}
      </h3>
      <p className="mt-2 font-display text-xl text-white">
        {feature.summary}
      </p>
      <p className="mt-4 text-sm text-white">{feature.description}</p>
    </div>
  )
}

function FeaturesMobile() {
  return (
    <div className="-mx-4 mt-20 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
      {features.map((feature) => (
        <div key={feature.name}>
          <Feature feature={feature} className="mx-auto max-w-2xl" isActive />
          <div className="relative mt-10 pb-10">
          </div>
        </div>
      ))}
    <div className="text-2xl text-white">
    4,000,000+ texts currently indexed in digitalfalse.
    </div>

    </div>
  )
}

function FeaturesDesktop() {
  return (
    <Tab.Group as="div" className="hidden lg:mt-20 lg:block">
      {({ selectedIndex }) => (
        <>
          <Tab.List className="grid grid-cols-3 gap-x-8">
            {features.map((feature, featureIndex) => (
              <Feature
                key={feature.name}
                feature={{
                  ...feature,
                  name: (
                    <Tab className="[&:not(:focus-visible)]:focus:outline-none">
                      <span className="absolute inset-0" />
                      {feature.name}
                    </Tab>
                  ),
                }}
                isActive={featureIndex === selectedIndex}
                className="relative"
              />
            ))}
          </Tab.List>
          <Tab.Panels className="relative mt-20 overflow-hidden px-14 py-16 xl:px-16 text-white text-center text-4xl">
            <div className="-mx-5 flex">
              {features.map((feature, featureIndex) => (
                <Tab.Panel
                  static
                  key={feature.name}
                  className={clsx(
                    'px-5 transition duration-500 ease-in-out [&:not(:focus-visible)]:focus:outline-none',
                    featureIndex !== selectedIndex && 'opacity-60'
                  )}
                  style={{ transform: `translateX(-${selectedIndex * 100}%)` }}
                  aria-hidden={featureIndex !== selectedIndex}
                >
                </Tab.Panel>
              ))}
            </div>
            <div className="pointer-events-none absolute inset-0">
              4,000,000+ texts currently indexed in digitalfalse.
            </div>
          </Tab.Panels>
        </>
      )}
    </Tab.Group>
  )
}

export function SecondaryFeatures() {
  return (
    <section
      id="features"
      aria-label="Features for simplifying everyday business tasks"
      className="pt-20 pb-14 sm:pb-20 sm:pt-32 lg:pb-32 bg-primary"
    >
      <Container>
        <div className="mx-auto max-w-2xl md:text-center">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
            Providing indicators to help decide whether a text has been authored by machine.
          </h2>
          <p className="mt-4 text-lg tracking-tight text-white">
            Utilising machine learning on millions of texts, written by human and AI.
          </p>
        </div>
        <FeaturesMobile />
        <FeaturesDesktop />
      </Container>
    </section>
  )
}
