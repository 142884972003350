import { CallToAction } from '../components/CallToAction'
import { Faqs } from '../components/Faqs'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { Hero } from '../components/Hero'
import { Pricing } from '../components/Pricing'
import { SecondaryFeatures } from '../components/SecondaryFeatures'
import '../index.css';

export default function Home() {
  return (
    <>
      <header>
        <title>Digitalfalse - Detecting AI in text and image</title>
      </header>
      <Header />
      <main className="">
        <Hero />
                  <CallToAction />
        <SecondaryFeatures />

        <Pricing />
        <Faqs />
      </main>
      <Footer />
    </>
  )
}
