import { Button } from './Button'
import { Container } from './Container'
import logoLaravel from '../images/logos/laravel.svg'
import logoMirage from '../images/logos/mirage.svg'
import logoStatamic from '../images/logos/statamic.svg'
import logoStaticKit from '../images/logos/statickit.svg'
import logoTransistor from '../images/logos/transistor.svg'
import logoTuple from '../images/logos/tuple.svg'

export function Hero() {
  return (
    <Container className="pt-20 pb-16 text-center lg:pt-32">
      <h1 className="mx-auto max-w-4xl font-display text-5xl font-medium tracking-tight text-slate-900 sm:text-7xl">AI detection, finding truth in digital falsehoods.
      </h1>

      <div className="mt-10 flex justify-center gap-x-6">
        <Button href="#get-started-today" color="blue">Try now</Button>
        <Button
          href="mailto:info@digitalfalse.ai"
          variant="outline"
        ><span className="">Contact</span>
        </Button>
      </div>
      <div className="mt-36 lg:mt-44">
        <p className="font-display text-base text-slate-900">
          We're receiving lots of hits against our API, this is great, thank you! Our models will continue to improve.
        </p>

      </div>
    </Container>
  )
}
