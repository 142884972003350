import {useAuth0} from "@auth0/auth0-react";
import {Footer} from '../components/Footer'
import {Header} from '../components/Header'
import React, {useEffect, useState} from "react";
import {apiClassification, apiGetDashboard} from "../api";
import {Container} from "../components/Container";
import {Link} from "react-router-dom";

export const Dashboard: React.FC = () => {

    const {user, isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0();

    const [token, setToken] = useState<string | undefined>(undefined)
    const [count, setCount] = useState<number>(0)
    const [tobeClassified, setInput] = useState<string | undefined>(undefined)

    const [ai, setAI] = useState<string>("")
    const [human, setHuman] = useState<string>("")
    const [notes, setNotes] = useState<string>("")

    async function getToken() {
        setToken(await getAccessTokenSilently());
    }

    useEffect(() => {
        if (token === undefined) {
            getToken()
            return;
        }

        apiGetDashboard(token).then((data: any) => {
            console.log(data)
        }).catch();

    }, [user, token])

    if (!user) {
        return null;
    }

    function classify(t: any) {
        t.preventDefault();

        if (tobeClassified !== undefined && user && token) {
            if (tobeClassified.length > 1000) {
                apiClassification(tobeClassified, 2, user?.sub ? user.sub : "", token).then((data: any) => {
                    console.log(data)
                    setAI(data["ai"]);
                    setHuman(data["human"]);
                    setNotes(data["notes"]);
                }).catch();
            }
        }
    }

    return (
        <>
            <header>
<title>Digitalfalse - Detecting AI in text and image</title>
            </header>
            <Header/>

            <div className="max-w-7xl justify-center" style={{minHeight: 'calc(100vh - 137px)', margin : "0 auto"}}>
                <div className="max-w-7xl justify-center p-2">
                    <h2>Classifications remaining: {count}</h2>
                    <h2 className="pt-2 px-5%">Please note this is an actively developed area, things can change and break. There are no restrictions currently other
                        than all text uploaded must be less than 1000 words. Models in the background will be tweaked and changed without notice. You can read about any updates at <Link to='/releasenotes'>/releasenotes</Link> <br/>Thanks for coming this far 👍  </h2>
                </div>
                <div className="flex flex-wrap justify-centre">
                    <div className="border p-4 w-50%" style={{width: "45%"}}>
                        <div className="">
                            <form onSubmit={(t) => classify(t)}>
                                <div
                                    className="w-full mb-4 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
                                    <div className="px-4 py-2 bg-white rounded-t-lg dark:bg-gray-800">
                                        <label htmlFor="comment" className="sr-only">Your comment</label>
                                        <textarea id="comment"
                                                  rows={12}
                                                  className="w-full px-0 text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                                  placeholder="Paste in text"
                                                  onChange={(e) => setInput(e.target.value)}
                                                  required></textarea>
                                    </div>
                                    <div
                                        className="flex items-center justify-between px-3 py-2 border-t dark:border-gray-600">


                                         <button type="submit"
                                        className="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-button-blue rounded-lg focus:ring-4 focus:button-blue-down dark:focus:button-blue-down hover:bg-button-blue-down">
                                    Submit
                                </button>
                                        <div className="flex flex-columns">
                                            <div className="pr-2">AI <input type="checkbox"/></div>
                                            <div className="">Human <input type="checkbox"/></div>
                                        </div>


                                    </div>


                                </div>

                            </form>
                            <p className="font-display tracking-tight sm:text-xl text-centre">
                                Free mode:
                            </p>
                            <ul className=" text-left left list-inside list-disc">
                                <li>Maximum: 1500 words</li>

                            </ul>


                        </div>


                    </div>
                    <div className="border p-4" style={{width: "45%"}}>
                        <div>Your classifications:</div>
                        {ai} <br/>
                        {human} <br/>
                        {notes} <br/>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}
