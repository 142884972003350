import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Auth0Provider } from "@auth0/auth0-react";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAb8pMrilYcm3NETTihy6uesKFGiWR72HY",
  authDomain: "digitalfalse-930bd.firebaseapp.com",
  projectId: "digitalfalse-930bd",
  storageBucket: "digitalfalse-930bd.appspot.com",
  messagingSenderId: "602778156109",
  appId: "1:602778156109:web:affbd1184df93fef597004",
  measurementId: "G-GTJ4RQRJ08"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
  const audience = "login.digitalfalse.ai";

const providerConfig = {
  domain: domain ? domain : "",
  clientId: clientId ? clientId : "",
  redirectUri,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(audience ? { audience: audience } : null),
  },
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
    <Auth0Provider {...providerConfig}>
    <App />
    </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
