import clsx from 'clsx'

import {Button} from '../components/Button'
import {Container} from '../components/Container'
import {SignupButton, SignupButtonStyled} from "./buttons/signup-button";


function CheckIcon({className}) {
    return (
        <svg
            aria-hidden="true"
            className={clsx(
                'h-6 w-6 flex-none fill-current stroke-current',
                className
            )}
        >
            <path
                d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z"
                strokeWidth={0}
            />
            <circle
                cx={12}
                cy={12}
                r={8.25}
                fill="none"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

function Plan({name, price, description, href, features, featured = false, disabled = false, hidden = false}) {
    return (
        <section
            className={clsx(
                'flex flex-col rounded-3xl px-6 sm:px-8',
                featured ? 'order-first bg-blue-600 py-8 lg:order-none' : 'lg:py-8',
                hidden ? 'hidden' : ''
            )}
        >
            <h3 className="mt-5 font-display text-lg text-white">{name}</h3>
            {disabled &&
                <p
                    className={clsx(
                        'mt-2 text-base text-white',
                    )}
                >

                    ...<br/>...

                </p>
            }

            {!disabled &&
            <p
                    className={clsx(
                        'mt-2 text-base text-white',
                    )}
                >

                {description}

                </p>
        }
            <p className="order-first font-display text-5xl font-light tracking-tight text-white">
                {price}
            </p>
            <ul
                role="list"
                className={clsx(
                    'order-last mt-10 flex flex-col gap-y-3 text-sm',
                    featured ? 'text-white' : 'text-slate-200'
                )}
            >
                {features.map((feature) => (
                    <li key={feature} className="flex">
                        <CheckIcon className={featured ? 'text-white' : 'text-slate-400'}/>
                        <span className="ml-4">{feature}</span>
                    </li>
                ))}
            </ul>
            <SignupButtonStyled/>
        </section>
    )
}

export function Pricing() {
    const getCurrencySymbol = (locale, currency) => (0).toLocaleString(locale, { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\d/g, '').trim()
    return (
        <section
            id="pricing"
            aria-label="Pricing"
            className="bg-slate-900 py-20 sm:py-32"
        >
            <Container>
                <div className="md:text-center">
                    <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
                        <span className="relative whitespace">Heavily reduced pricing as our dataset grows.</span>
                    </h2>
                    <p className="mt-4 text-lg text-slate-400">
                        We offer additional credits for every text you upload which is labelled as written by Human or AI.
                    </p>
                </div>
                <div
                    className="-mx-4 mt-16 grid max-w-2xl grid-cols-1 gap-y-10 sm:mx-auto lg:-mx-8 lg:max-w-none lg:grid-cols-3 xl:mx-0 xl:gap-x-8">
                    <Plan
                        name="Starter"
                        price="Free"
                        description="Basics, a heavily reduced price as one of our first backers."
                        href="/register"
                        features={[
                            'Upto 10 texts a day',
                            '1000 word limit',
                            'Singular upload',
                            'Singlular analysis, "we\'re 85% sure, this is written by a machine',
                            'Text only',
                            '1 seat',
                        ]}
                    />

                </div>
            </Container>
        </section>
    )
}


//<Plan
//                         name="Explorer"
//                         price="9.99$, monthly"
//                         description="Giving you more capacity to upload, with a larger size text."
//                         href="/register"
//                         features={[
//                             'Upto 100 texts a day',
//                             '1500 word limit',
//                             'Singular upload',
//                             'Singlular analysis, "we\'re 85% sure, this is written by a machine',
//                             'Text only',
//                             '1 seat',
//                         ]}
//                     />
//                     <Plan
//                         name="..."
//                         price="..."
//                         description="..."
//                         href="/register"
//                         features={[
//                             '...',
//                             '...',
//                             '..',
//                         ]}
//                         disabled
//                         hidden
//
//                     />
//
//                     <Plan
//                         name="..."
//                         price="..."
//                         description="..."
//                         href="/register"
//                         features={[
//                             '...',
//                             '...',
//                             '..',
//                         ]}
//                         disabled
//                         hidden
//                     />