import React from 'react';
import './App.css';
import Home from "./pages/home";
import {Notes} from "./pages/notes";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {Dashboard} from "./pages/dashboard";
import {useAuth0} from "@auth0/auth0-react";

function App() {

const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
      return <div>loading</div>;
  }


  return (
        <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/dashboard" element={<Dashboard/>}/>
        <Route path="/profile" element={<Dashboard/>}/>
        <Route path="/releasenotes" element={<Notes/>}/>

      </Routes>
    </BrowserRouter>
  );
}

export default App;
