import {Container} from '../components/Container'
import {useEffect, useState} from "react";
import {apiClassification} from "../api";

export function CallToAction() {

    const [tobeClassified, setInput] = useState<string | undefined>(undefined)
    const [ai, setAI] = useState<string>("")
    const [human, setHuman] = useState<string>("")
    const [notes, setNotes] = useState<string>("")
    const [err, setError] = useState<string>("")

    function classify(t: any) {
        t.preventDefault();
        console.log("clicked")
        console.log(tobeClassified)
        setError("");

        if (tobeClassified !== undefined) {
            if (tobeClassified.length > 600) {
                apiClassification(tobeClassified, 2, "", "").then((data: any) => {
                    console.log(data)
                    setAI(data["ai"]);
                    setHuman(data["human"]);
                    setNotes(data["notes"]);
                }).catch();
            } else{
                setError("Text added is too short.")
            }
        }
    }

    function paste(t: any) {

        let clipboardData = t.clipboardData || window.Clipboard;
        let pastedText = clipboardData.getData('text');
        console.log(pastedText)

        setInput(pastedText);

    }

    return (
        <section
            id="get-started-today"
            className="relative overflow-hidden bg-ternary py-32"
        >

            <Container className="relative">
                <div className="mx-auto max-w-lg text-center">
                    <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
                        Try now:
                    </h2>
                    <p className="mt-4 text-lg tracking-tight text-white">
                        Paste in text, and let our system analise.
                    </p>


                    <form onSubmit={(t) => classify(t)}>
                        <div
                            className="w-full mb-4 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-700 dark:border-gray-600">
                            <div className="px-4 py-2 bg-white rounded-t-lg dark:bg-gray-800">
                                <label htmlFor="comment" className="sr-only">Your comment</label>
                                <textarea id="comment"
                                          rows={10}
                                          onPaste={(e) => paste(e)}
                                          onChange={(e) => setInput(e.target.value)}
                                          className="w-full px-0 text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                          placeholder="Paste in text" required></textarea>
                            </div>
                            <div className="flex items-center justify-between px-3 py-2 border-t dark:border-gray-600">
                                <button type="submit"
                                        className="bg-black inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-button-blue rounded-lg focus:ring-4 focus:button-blue-down dark:focus:button-blue-down hover:bg-button-blue-down">
                                    Submit
                                </button>
                                <div className="flex flex-columns">
                                <div className="pr-2">AI <input type="checkbox"/></div> <div className="">Human <input type="checkbox"/></div>
                                    </div>


                            </div>

                            {notes &&
                                <div>Results:
                                    {notes}<br/>
                                </div>
                            }
                        </div>
                    </form>

                                        <p className="mt-4 text-lg tracking-tight text-white">
                        Okay, so with GPT3.5 and 4, we're a long way off in terms of correct classification. There are still some indicators that are flagged correctly, but we're rethinking our algorithm. To help us, if you know the author source for the text you're uploading, please tick the corresponding box, it really helps up learn!
                    </p>

                </div>
            </Container>
        </section>
    )
}
